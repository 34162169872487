import debounce from "debounce";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./Dashboard.module.scss";

import {
  GetGamesParams,
  UpdateGameConfigParams,
  getGameConfigsOfUser,
  getGames,
  updateGameConfigOfUser,
} from "appdata/games/gamesSlice";
import { AppDispatch, RootState } from "appdata/store";
import CognitiveIcon from "assets/icons/cognitive.svg";
import NeckIcon from "assets/icons/neck.svg";
import NoDataImg from "assets/img/no-data.webp";
import PaginationCus from "components/PaginationCus/PaginationCus";
import { useNotification } from "contexts/notification-context/NotificationContext";
import { CATEGORIES } from "helps/Categories";
import { UNITS, UNIT_CONFIG } from "helps/UnitConfig";
import { BaseApiParams } from "types/apiTypes";
import {
  FlipFoodConfig,
  Game,
  GameConfig,
  HandShaperConfig,
} from "types/gameTypes";

const categoryIcons: { [key in keyof typeof CATEGORIES]: string | null } = {
  COGNITIVE: CognitiveIcon,
  NECK: NeckIcon,
  ALL: null,
};

const PAGE_SIZE = 8;

function Dashboard() {
  const { openNotification } = useNotification();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const leftRef = useRef<HTMLDivElement | null>(null);
  const rightRef = useRef<HTMLDivElement | null>(null);
  const testRef = useRef<HTMLImageElement | null>(null);

  const myselfRedux = useSelector((state: RootState) => state.myselfRedux);
  const gamesRedux = useSelector((state: RootState) => state.gamesRedux);

  const [selectedCategory, setSelectedCategory] = useState<string>(
    CATEGORIES.ALL
  );
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(
    gamesRedux.games?.[0]
  );
  const [configOfSelectedGame, setConfigOfSelectedGame] =
    useState<GameConfig | null>(null); // Thay đổi kiểu nếu có thông tin cụ thể
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [isConfigGamePopupOpen, setIsConfigGamePopupOpen] =
    useState<boolean>(false);

  const handlePageChange = (paramPaging: BaseApiParams) => {
    dispatch(
      getGames({
        ...paramPaging,
        limit: PAGE_SIZE,
      })
    );
  };

  const playNow = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length === 0) {
        openNotification(
          "error",
          "Error!",
          "No camera detected! Please connect a camera to play."
        );

        return;
      }

      const savedCameraId = localStorage.getItem("defaultCameraId");
      const selectedCameraId =
        savedCameraId &&
        videoDevices.some((device) => device.deviceId === savedCameraId)
          ? savedCameraId
          : videoDevices[0].deviceId;
      startGameWithCamera(selectedCameraId);
    });
  };

  const startGameWithCamera = (cameraId: string) => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: cameraId } })
      .then(() => {
        const updateParams: UpdateGameConfigParams = {
          gameId: selectedGame!.id,
          config: {
            config: configOfSelectedGame!,
          },
        };

        dispatch(updateGameConfigOfUser(updateParams))
          .unwrap()
          .then(() => {
            setTimeout(() => {
              let url = `/gameplay?game=${selectedGame!.configName}&userID=${
                myselfRedux.me!.username
              }`;

              if (selectedGame?.configName === "hand_shaper") {
                url += `&totalHand=${
                  (configOfSelectedGame as HandShaperConfig).totalHand
                }`;
              }

              if (selectedGame?.configName === "flip_food") {
                url += `&totalHand=${
                  (configOfSelectedGame as FlipFoodConfig).totalHand
                }`;
              }

              navigate(url);
            }, 200);
          })
          .catch((error) => {
            openNotification(
              "error",
              "Error!",
              "Error updating game configuration!"
            );
          });
      })
      .catch(() => {
        openNotification(
          "error",
          "Error!",
          "Unable to access the selected camera."
        );
      });
  };

  const handleInputBlur = (
    key: keyof GameConfig,
    min: number | null = null,
    max: number | null = null,
    value: number | string | null = null
  ) => {
    let newValue: any = value;

    if (typeof value === "string") {
      newValue = value.replace(/^0+(?=\d)/, "");
    }

    newValue = Number(value);

    if (min !== null && newValue < min) {
      newValue = min;
    }
    if (max !== null && newValue > max) {
      newValue = max;
    }

    setConfigOfSelectedGame((prevConfig) => ({
      ...prevConfig,
      [key]: newValue,
    }));
  };

  const handleInputChange =
    (key: keyof GameConfig) =>
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      let { value } = event.target;

      let newValue: any = value;

      const keysToConvert = ["calculationDifficulty", "gameLevel", "totalHand"];

      if (keysToConvert.includes(key)) {
        newValue = Number(value);
      }
      setConfigOfSelectedGame((prevConfig) => ({
        ...prevConfig,
        [key]: key === "isHeadDownMode" ? value === "true" : newValue,
      }));
    };

  const handleGameClick = (game: Game) => {
    setSelectedGame(game);
  };

  useEffect(() => {
    if (!gamesRedux.games || !gamesRedux.gameConfigsOfUser) return;

    setConfigOfSelectedGame(
      gamesRedux.gameConfigsOfUser[`${selectedGame!.configName}_config`]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  useEffect(() => {
    const getGamesDebounce = debounce(() => {
      const params: GetGamesParams = {
        page: 1,
        limit: PAGE_SIZE,
        name: valueSearch,
        type: selectedCategory !== "all" ? [selectedCategory] : undefined,
      };

      dispatch(getGames(params));
    }, 300);

    getGamesDebounce();
    return () => getGamesDebounce.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, valueSearch]);

  useEffect(() => {
    if (!gamesRedux.games) return;

    setSelectedGame(gamesRedux.games[0]);
  }, [gamesRedux]);

  useEffect(() => {
    const params: GetGamesParams = {
      page: 1,
      limit: PAGE_SIZE,
    };

    dispatch(getGames(params));
    dispatch(getGameConfigsOfUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.dashboard_wrapper}>
      {!isConfigGamePopupOpen && (
        <div className={styles.tool_box}>
          <div className={`${styles.category_wrapper}`}>
            {Object.keys(CATEGORIES).map((key) => {
              const categoryValue = CATEGORIES[key as keyof typeof CATEGORIES];
              const Icon =
                categoryIcons[categoryValue as keyof typeof categoryIcons];

              return (
                <span
                  key={key}
                  className={`${
                    selectedCategory === categoryValue
                      ? `text_gradient ${styles.selected_category}`
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedCategory(categoryValue);
                  }}
                >
                  {categoryValue !== "all" && Icon && (
                    <img src={Icon} alt={categoryValue} />
                  )}
                  {t(`game_categories.${categoryValue}`)}
                </span>
              );
            })}
          </div>

          <div
            className={`${styles.search_bar} ${
              isSearchActive ? styles.active : ""
            }`}
            onClick={() => {
              searchRef.current!.focus();
              setIsSearchActive(true);
            }}
          >
            <div className={styles.search_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001a1 1 0 0 0 .152 1.318l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85a1 1 0 0 0-1.318-.152zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
            <input
              ref={searchRef}
              type="text"
              placeholder={t("common_text.search")}
              className={styles.search_input}
              value={valueSearch}
              onChange={(e) => {
                const newValue = e.target.value;
                setValueSearch(newValue);
                setIsSearchActive(newValue.trim() !== "");
              }}
              onFocus={() => setIsSearchActive(true)}
              onBlur={() => {
                if (valueSearch.trim() === "") {
                  setIsSearchActive(false);
                }
              }}
            />
          </div>
        </div>
      )}
      {!isConfigGamePopupOpen && (
        <div className={`${styles.dashboard_container} no_scrollbar`}>
          {selectedGame && (
            <div className={styles.game_list}>
              {gamesRedux.games &&
                gamesRedux.games.map((game, index) => (
                  <div
                    key={game.id}
                    className={`${styles.game_item} ${
                      selectedGame?.name === game.name ? styles.selected : ""
                    }`}
                    onClick={() => handleGameClick(game)}
                  >
                    <div className={styles.image_container}>
                      <img
                        src={game.metadata.thumbnail}
                        alt={game.name}
                        className={styles.game_image}
                      />
                      <div className={styles.overlay}>
                        <button
                          className={`${styles.config_button} rounded`}
                          onClick={() => {
                            handleGameClick(game);
                            setIsConfigGamePopupOpen(true);
                          }}
                        >
                          {t("common_text.configPlay")}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`
                    ${selectedGame?.name === game.name ? "text_gradient" : ""}
                    ${styles.game_name}`}
                    >
                      {t(`game_name.${game.configName}`)}
                    </div>
                  </div>
                ))}
            </div>
          )}

          {gamesRedux.metadataGamesPage && (
            <PaginationCus
              metadataPage={gamesRedux.metadataGamesPage}
              onClick={handlePageChange}
              styles={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1vw",
              }}
            />
          )}
          {!selectedGame && (
            <div className={styles.table_nodata}>
              <img src={NoDataImg} alt="nodata"></img>
              <span>{t("common_text.noData")}</span>
            </div>
          )}
        </div>
      )}

      {isConfigGamePopupOpen && selectedGame && configOfSelectedGame && (
        <div className={styles.config_game_container}>
          <div ref={leftRef} className={styles.left}>
            <div className={styles.config_description}>
              <h2 className="text_gradient">{t("common_text.description")}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`game_description.${selectedGame!.configName}`),
                }}
              />
            </div>

            <div className={styles.config_tab}>
              <h2 className="text_gradient">
                {t("common_text.configuration")}
              </h2>

              <div className={styles.config_scroll}>
                {selectedGame.metadata.conditions.map((condition) => {
                  return condition.type === "select" ? (
                    <div className={styles.config_item} key={condition.key}>
                      <label>{t(`game_config.${condition.key}`)}</label>
                      <div className={styles.input_wrapper}>
                        <select
                          value={
                            configOfSelectedGame[
                              condition.key as keyof typeof configOfSelectedGame
                            ]
                          }
                          onChange={handleInputChange(
                            condition.key as keyof GameConfig
                          )}
                          className="size_medium"
                        >
                          {condition.options?.map((option) => {
                            return (
                              <option value={option.value}>
                                {t(`game_config.${option.label}`)}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.config_item} key={condition.key}>
                      <label>{t(`game_config.${condition.key}`)}</label>
                      <div className={styles.input_wrapper}>
                        <input
                          type="number"
                          value={
                            configOfSelectedGame[
                              condition.key as keyof typeof configOfSelectedGame
                            ]
                          }
                          onBlur={() => {
                            handleInputBlur(
                              condition.key as keyof GameConfig,
                              condition?.min ?? null,
                              condition?.max ?? null,
                              configOfSelectedGame[
                                condition.key as keyof typeof configOfSelectedGame
                              ]
                            );
                          }}
                          onChange={(e) => {
                            handleInputChange(
                              condition.key as keyof GameConfig
                            )(e);
                          }}
                          onKeyDown={(e) => {
                            if (["-", "e", "."].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          className="size_medium"
                        />
                        <span className={styles.unit}>
                          {UNIT_CONFIG[condition.key] !== UNITS.NONE &&
                            `(${t(`unit.${UNIT_CONFIG[condition.key]}`)})`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div ref={rightRef} className={styles.right}>
            <div className={styles.overlay}></div>
            <img
              ref={testRef}
              src={selectedGame.metadata.fullImage}
              alt="img"
            />
            <div className={styles.float}>
              <span className={styles.gameName}>
                {t(`game_name.${selectedGame.configName}`)}
              </span>
              <button
                className={`${styles.play_button} button_gradient_blue rounded size_large`}
                onClick={playNow}
              >
                {t("common_text.playNow")}
              </button>
            </div>
          </div>
          <button
            className={`${styles.close_btn} size_1 rounded`}
            onClick={() => {
              setIsConfigGamePopupOpen(false);
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
